import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './Gymnasio.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Gymnasio = () => {
  return (
    <div className="Gymnasio">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="Gymnasio__main-container">
          <div className="Gymnasio__container">
            <div className="Gymnasio__container-h">
              <h1>ΓΥΜΝΑΣΙΟ</h1>
              <div className="Gymnasio__container-p">
                <p>Ο μαθητής του Γυμνασίου είναι πολύ σημαντικό να συμμετέχει σε ένα φροντιστηριακό πρόγραμμα σπουδών όπου είναι ικανό να διακρίνει τις κλίσεις του και τα ενδιαφέροντά του. Έτσι, θα προετοιμαστεί συστηματικά και μεθοδικά αναπτύσσοντας δυναμικά τις δικές του δεξιότητες γιατί:</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Συμμετέχει σε ολιγομελή τμήματα με βάση το γνωστικό του επίπεδο</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Μαθαίνει να μελετά μόνος του</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Τα μαθήματα διδάσκονται από εξειδικευμένους καθηγητές κάθε ειδικότητας</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Διδάσκονται όλα τα μαθήματα του Γυμνασίου</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Χτίζει τις βάσεις που απαιτούνται για τις τάξεις του Λυκείου</p>
                <p>&nbsp;&nbsp;&nbsp;&bull; Αποκτά αυτενέργεια και αυτοπεποίθηση</p>
                <p>Η σωστή προετοιμασία είναι το κλειδί της επιτυχίας!</p>
              </div>
              <div className="Gymnasio__container-h">
                <h2>Ωρολόγιο πρόγραμμα του Γυμνασίου:</h2>
              </div>
              <div className="Gymnasio__container-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="4">Γ΄ ΓΥΜΝΑΣΙΟΥ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td></td>
                        <td>ΜΑΘΗΜΑΤΑ</td>
                        <td>1-3 ΑΤΟΜΑ</td>
                        <td>3-6 ΑΤΟΜΑ</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>Μαθηματικά</td>
                        <td>2 ώρες /εβδ</td>
                        <td>3 ώρες /εβδ</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Έκθεση</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Φυσική</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Αρχαία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Χημεία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Βιολογία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="4">Β΄ ΓΥΜΝΑΣΙΟΥ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td></td>
                        <td>ΜΑΘΗΜΑΤΑ</td>
                        <td>1-3 ΑΤΟΜΑ</td>
                        <td>3-6 ΑΤΟΜΑ</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>Μαθηματικά</td>
                        <td>2 ώρες /εβδ</td>
                        <td>3 ώρες /εβδ</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Έκθεση</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Φυσική</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Αρχαία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Χημεία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Βιολογία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="4">Α΄ ΓΥΜΝΑΣΙΟΥ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td></td>
                        <td>ΜΑΘΗΜΑΤΑ</td>
                        <td>1-3 ΑΤΟΜΑ</td>
                        <td>3-6 ΑΤΟΜΑ</td>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>Μαθηματικά</td>
                        <td>2 ώρες /εβδ</td>
                        <td>3 ώρες /εβδ</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Έκθεση</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Φυσική</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Αρχαία</td>
                        <td>1 ώρα /εβδ</td>
                        <td>1 ώρα /εβδ</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="Gymnasio__side-Containers">
              <div className="Gymnasio__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Πληροφορίες για Γ΄Λυκείου
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Μάθετε περισσότερα για την λειτουργεία του φροντιστηρίου μας
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σπουδές και επαγγέλματα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Gymnasio