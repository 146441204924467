import React, { useRef, useState }  from 'react';
import emailjs from '@emailjs/browser';
import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './A-Lykeiou.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import rightArrow from "../../../../assets/right-arrows.png";
import OEFE from '../../../../assets/OEFE.jpg';
import labora from '../../../../assets/labora.jpg';
import edu4schools from '../../../../assets/edu4schools.png';
import ypourgeio from '../../../../assets/ypourgeio.jpg';

  


const Alykeiou = () => {

  const form = useRef();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ald9cce', 'template_optgjil', form.current, 'EVgIFCLIjxstCs_IK')
      .then((result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          setShowSuccess(true);
          setShowError(false);
      }, (error) => {
          console.log(error.text);
          setShowSuccess(false);
          setShowError(true);
      });
  };

  const closePopup = () => {
    setShowSuccess(false);
    setShowError(false);
  };

  return (
    <div className="Alykeiou">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="ALykeiou__main-container">
          <div className="ALykeiou__container">
            <div className="ALykeiou__container-h">
              <h1>Α’ ΛΥΚΕΙΟΥ</h1>
              <div className="ALykeiou__container-p">
                <p>Ο μαθητής της Α’ Λυκείου παρακολουθεί μαθήματα για μια ολοκληρωμένη κάλυψη γενικών γνώσεων και του δίνεται η δυνατότητα, με την βοήθεια επαγγελματικού προσανατολισμού να επιλέξει από τόσο νωρίς, την κατάλληλη ομάδα προσανατολισμού που καλείται να ακολουθήσει στην Β’ Λυκείου.</p>
              </div>
              <div className="ALykeiou__container-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="4">ΜΑΘΗΜΑΤΑ ΓΕΝΙΚΗΣ ΠΑΙΔΕΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td rowSpan="2">ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td><img src={rightArrow} alt="Δεξί βέλος"></img></td>
                        <td>ΑΛΓΕΒΡΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr class="bottom-border">
                        <td><img src={rightArrow} alt="Δεξί βέλος"></img></td>
                        <td>ΓΕΩΜΕΤΡΙΑ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td rowSpan="3">ΦΥΣΙΚΕΣ ΕΠΙΣΤΗΜΕΣ</td>
                        <td><img src={rightArrow} alt="Δεξί βέλος"></img></td>
                        <td>ΦΥΣΙΚΗ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td><img src={rightArrow} alt="Δεξί βέλος"></img></td>
                        <td>ΧΗΜΕΙΑ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr class="bottom-border">
                        <td><img src={rightArrow} alt="Δεξί βέλος"></img></td>
                        <td>ΒΙΟΛΟΓΙΑ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td rowSpan="2">ΦΙΛΟΛΟΓΙΚΑ</td>
                        <td><img src={rightArrow} alt="Δεξί βέλος"></img></td>
                        <td>ΝΕΑ ΕΛΛΗΝΙΚΗ ΓΛΩΣΣΑ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td><img src={rightArrow} alt="Δεξί βέλος"></img></td>
                        <td>ΑΡΧΑΙΑ ΕΛΛΗΝΙΚΗ ΓΛΩΣΣΑ & ΓΡΑΜΜΑΤΕΙΑ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="ALykeiou__side-Containers">
              <div className="ALykeiou__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σημεία Υπεροχής
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <a href="/Frontistirio/Paroxes-Ypiresiwn/" target="_blank" rel="noopener noreferrer">
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </a>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Ομάδα ή Μονάδα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Πρόταση "ΑΝΟΔΟΥ"
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σχόλια Μαθητών
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <a href="/PoioiEimaste/Oi-Mathites-Mas/" target="_blank" rel="noopener noreferrer">
                            <button class="custom-btn btn-5">Λεπτομέρειες</button>
                          </a>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
              <div className="anodos__alykeiou-email-container">
              <form className="anodos__alykeiou-email-container_form" ref={form} onSubmit={sendEmail}>
                <label className="anodos__alykeiou-email-container_label">Όνομα</label>
                <input className="anodos__alykeiou-email-container_input" type="text" name="user_name" required/>
                <label className="anodos__alykeiou-email-container_label">Email</label>
                <input className="anodos__alykeiou-email-container_input" type="email" name="user_email" required/>
                <label className="anodos__alykeiou-email-container_label">Μήνυμα</label>
                <textarea className="anodos__alykeiou-email-container_input-message" name="message" required/>
                <input className="anodos__alykeiou-email-container_btn" type="submit" value="Αποστολή" />
              </form>

              {showSuccess && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--success">
                  <span>Your message was sent successfully!</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}

              {showError && (
                <div className="anodos__alykeiou-email-container_popup anodos__alykeiou-email-container_popup--error">
                  <span>There was an error sending your message. Please try again later.</span>
                  <button className="anodos__alykeiou-email-container_close" onClick={closePopup}>X</button>
                </div>
              )}
              </div>
            <div className="anodos__Alukeiou-links-container">
                <a href="https://www.oefe.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alukeiou-links-container_box" style={{ backgroundImage: `url(${OEFE})` }}></div>
                </a>
                <a href="https://labora.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alukeiou-links-container_box" style={{ backgroundImage: `url(${labora})` }}></div>
                </a>
                <a href="https://www.edu4schools.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alukeiou-links-container_box" style={{ backgroundImage: `url(${edu4schools})` }}></div>
                </a>
                <a href="https://www.minedu.gov.gr/" target="_blank" rel="noopener noreferrer">
                    <div className="anodos__Alukeiou-links-container_box" style={{ backgroundImage: `url(${ypourgeio})` }}></div>
                </a>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Alykeiou