import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './B-Lykeiou.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const BLykeiou = () => {
  return (
    <div className="BLykeiou">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="BLykeiou__main-container">
          <div className="BLykeiou__container">
            <div className="BLykeiou__container-h">
              <h1>Β’ ΛΥΚΕΙΟΥ</h1>
              <div className="BLykeiou__container-p">
                <p>Ο μαθητής της Β’ Λυκείου παρακολουθεί μαθήματα σύμφωνα με το πρόγραμμα σπουδών που προτίθεται να επιλέξει στην Γ’ Λυκείου. Συγκεκριμένα, το πρόγραμμα σπουδών που ενδείκνυται να ακολουθήσει καλύπτει τόσο τις ανάγκες για τα μαθήματα της Β’ Λυκείου όσο και τις βάσεις που απαιτούνται για την Γ’ τάξη.</p>
                <p> Ωστόσο, του δίνεται η δυνατότητα του Επαγγελματικού προσανατολισμού, σε περίπτωση που δεν έχει επιλέξει έγκαιρα την κατεύθυνσή του, μια παροχή που θα τον οδηγήσει πιο κοντά στον στόχο του.</p>
              </div>
              <div className="BLykeiou__container-h">
                <h2>Ωρολόγιο πρόγραμμα Β΄ Λυκείου:</h2>
              </div>
              <div className="BLykeiou__container-table">
              <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΜΑΘΗΜΑΤΑ ΓΕΝΙΚΗΣ ΠΑΙΔΕΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>ΑΛΓΕΒΡΑ - ΓΕΩΜΕΤΡΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΧΗΜΕΙΑ ΓΕΝΙΚΗΣ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΦΥΣΙΚΗ ΓΕΝΙΚΗΣ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΕΚΘΕΣΗ - ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΒΙΟΛΟΓΙΑ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
              <div className="BLykeiou__container-h">
                <h2>ΕΠΙΠΛΕΟΝ, ΜΑΘΗΜΑΤΑ ΑΝΑ ΕΠΙΣΤΗΜΟΝΙΚΟ ΠΕΔΙΟ</h2>
              </div>
              <div className="BLykeiou__container-table">
              <table>
                  <thead>
                    <tr>
                      <th colSpan="2" class="right-border">1. ΑΝΘΡΩΠΙΣΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                      <th colSpan="2">2. ΘΕΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>ΑΡΧΑΙΑ</td>
                        <td class="right-border">4 ώρες/ εβδομάδα</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ ΠΡΟΣΑΝΑΤΟΛΙΣΜΟΥ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>ΛΑΤΙΝΙΚΑ</td>
                        <td class="right-border">1 ώρα/ εβδομάδα</td>
                        <td>ΦΥΣΙΚΗ ΠΡΟΣΑΝΑΤΟΛΙΣΜΟΥ</td>
                        <td>2 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td class="right-border"></td>
                        <td>ΧΗΜΕΙΑ Γ' ΛΥΚΕΙΟΥ</td>
                        <td>1 ώρα/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="BLykeiou__side-Containers">
              <div className="BLykeiou__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Πληροφορίες για Γ΄Λυκείου
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Μάθετε περισσότερα για την λειτουργεία του φροντιστηρίου μας
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σπουδές και επαγγέλματα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default BLykeiou