import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './Proetimasia-G-Lykeiou.css';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Proetimasia = () => {
  return (
    <div className="Proetimasia">
        <div className="gradient__bg">
          <Navbar />
        </div>
        <div className="Proetimasia__main-container">
          <div className="Proetimasia__container">
            <div className="Proetimasia__container-h">
              <h1>ΘΕΡΙΝΗ ΠΡΟΕΤΟΙΜΑΣΙΑ ΠΑΝΕΛΛΗΝΙΩΝ</h1>
              <div className="Proetimasia__container-p">
                <p>Γνωρίζοντας τις πολλαπλές απαιτήσεις των δύο τελευταίων τάξεων και αξιοποιώντας την πολυετή εμπειρία μας δημιουργήσαμε ένα Διετές Πρόγραμμα Σπουδών, ώστε ο μαθητής μας:</p>
                <p>Να καλύψει όλα τα γνωστικά κενά των προηγούμενων τάξεων ώστε να διασφαλίσει την επιτυχία του.</p>
                <p>Να ανταποκριθεί με επιτυχία στις απαιτήσεις της τάξης και να πετύχει ΑΡΙΣΤΕΣ επιδόσεις.</p>
                <p>Να αποκτήσει την απαραίτητη υποδομή, ώστε να αντιμετωπίσει με μεγαλύτερη άνεση τα μαθήματα της Γ΄ Λυκείου.</p>
                <p>Να επιτύχει ομαλή μετάβαση στη Γ΄ Λυκείου, ώστε να πετύχει τον στόχο του στις Πανελλαδικές εξετάσεις.</p>
              </div>
              <div className="Proetimasia__container-p2">
                <p>Ο στόχος μας είναι : </p>
                <p>&bull; η πλήρης και χρονικά εμπρόθεσμη κάλυψη της ύλης.</p>
                <p>&bull; να διαπιστωθούν έγκαιρα οι ανάγκες των μαθητών ώστε να καλυφθούν με επιπλέον μαθήματα.</p>
                <p>&bull; ψυχολογική προετοιμασία για τις εξετάσεις.</p>
                <p>&bull; ολοκληρωμένη προετοιμασία για τα θέματα των εξετάσεων.</p>
              <div className="Proetimasia__container-h">
                <h2>ΤΟ ΘΕΡΙΝΟ ΠΡΟΓΡΑΜΜΑ ΠΡΟΕΤΟΙΜΑΣΙΑΣ ΠΕΡΙΛΑΜΒΑΝΕΙ:</h2>
                <div className="Proetimasia__container-p">
                  <p>Μαθήματα διάρκειας 6 εβδομάδων.</p>
                  <p> Οι 4 πρώτες εβδομάδες ξεκινούν στις 14 Ιουνίου και ολοκληρώνονται στις 18 Ιουλίου. Η 5η και η 6η εβδομάδα των θερινών μαθημάτων πραγματοποιούνται από 18 έως 31 Αυγούστου.</p>
                </div>
              </div>
              </div>
              <div className="Proetimasia__container-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΘΕΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>5 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΦΥΣΙΚΗ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΧΗΜΕΙΑ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>16 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΣΠΟΥΔΩΝ ΟΙΚΟΝΟΜΙΑΣ & ΠΛΗΡΟΦΟΡΙΚΗΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΜΑΘΗΜΑΤΙΚΑ</td>
                        <td>5 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΟΙΚΟΝΟΜΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Α.Ε.Π.Π.</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>14 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΣΠΟΥΔΩΝ ΥΓΕΙΑΣ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΒΙΟΛΟΓΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΦΥΣΙΚΗ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΧΗΜΕΙΑ</td>
                        <td>4 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>14 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th colSpan="3">ΑΝΘΡΩΠΙΣΤΙΚΩΝ ΣΠΟΥΔΩΝ</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>1</td>
                        <td>ΑΡΧΑΙΑ</td>
                        <td>5 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>ΛΑΤΙΝΙΚΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>ΙΣΤΟΡΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>ΕΚΘΕΣΗ & ΛΟΓΟΤΕΧΝΙΑ</td>
                        <td>3 ώρες/ εβδομάδα</td>
                      </tr>
                      <tr>
                        <td colSpan="2">ΣΥΝΟΛΟ</td>
                        <td>14 ώρες/ εβδομάδα</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="Proetimasia__side-Containers">
              <div className="Proetimasia__side-Container1">
                <h1>Χρήσιμες Σελίδες</h1>
                <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Πληροφορίες για Γ΄Λυκείου
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Exercitation in fugiat est ut ad ea cupidatat ut in
                          cupidatat occaecat ut occaecat consequat est minim minim
                          esse tempor laborum consequat esse adipisicing eu
                          reprehenderit enim.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                  </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Μάθετε περισσότερα για την λειτουργεία του φροντιστηρίου μας
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Σπουδές και επαγγέλματα
                        </AccordionItemButton>
                      </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In ad velit in ex nostrud dolore cupidatat consectetur
                            ea in ut nostrud velit in irure cillum tempor laboris
                            sed adipisicing eu esse duis nulla non.
                          </p>
                          <button class="custom-btn btn-5">Λεπτομέρειες</button>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
              </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Proetimasia