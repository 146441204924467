import React from 'react';

import { Footer } from '../../../../containers';
import { Navbar } from '../../../../components';
import './Paroxes-Ypiresiwn.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const Paroxes = () => {
  return (
    <div className="Paroxes">
        <div className="gradient__bg">
          <Navbar className="navbar" />
        </div>
        <div className="Paroxes__main-container">
          <div className="Paroxes__container">
            <div className="Paroxes__container-h">
              <h1>ΠΑΡΟΧΕΣ ΥΠΗΡΕΣΙΩΝ</h1>
            </div>
            <div className="Paroxes__container-p">
            <p>&nbsp;&nbsp;&nbsp;Το φροντιστήριο από το 1999 δραστηριοποιείται στον τομέα της παροχής εκπαιδευτικών υπηρεσιών σε φροντιστηριακό επίπεδο για την Δευτεροβάθμια εκπαίδευση.</p>
                  <p>&nbsp;&nbsp;&nbsp;Διδάσκονται μαθήματα Μέσης Εκπαίδευσης σε μαθητές Γυμνασίου και Α', Β' , Γ’ Λυκείου και ΕΠΑ.Λ. καθώς τους προετοιμάζει για τις πανελλαδικές εξετάσεις. Τα μαθήματα που διδάσκονται είναι:
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Μαθηματικά Προσανατολισμού,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Έκθεση & Λογοτεχνία,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Φυσική-Χημεία Προσανατολισμού,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Βιολογία Προσανατολισμού,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Αρχές Οικονομικής Θεωρίας (Οικονομία),</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Ανάπτυξη Εφαρμογών σε Προγραμματιστικό Περιβάλλον (Α.Ε.Π.Π.),</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Αρχαία,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Λατινικά,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Ιστορία ,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Άλγεβρα & Γεωμετρία,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Φυσική-Χημεία γενικής παιδείας,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; ΑΟΔΕ (Αρχές Οργάνωσης και Διοίκησης Επιχειρήσεων),</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Υγιεινή & Ανατομία,</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x21D2; Δίκτυα & Προγραμματισμός,</p>
            </p>
              <p>&nbsp;&nbsp;&nbsp;Παρέχουμε:
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Ομοιογενή τμήματα</p> 
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Συνεχής επικοινωνία με μαθητές και γονείς</p> 
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Εκδηλώσεις επαγγελματικού προσανατολισμού</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Προγραμματισμένα  διαγωνίσματα</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Συχνά test κατά την εκτίμηση του υπεύθυνου καθηγητή</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Test Επαγγελματικού Προσανατολισμού</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Επαναληπτικά διαγωνίσματα της ΟΕΦΕ</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Πλούσιο Εκπαιδευτικό Υλικό</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Σύγχρονοι μέθοδοι διδασκαλίας μέσω διαδραστικών πινάκων</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Χρήση Πληροφοριακών συστημάτων (Έπαφος)</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Υπολογισμός επιδόσεων των μαθητών μας και επαναξιολόγηση των αρχικών τους στόχων</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Προσομοίωση συμπλήρωσης μηχανογραφικού δελτίου</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Συμπληρωματικές δραστηριότητες της εκπαιδευτικής διαδικασίας</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Διανομή ενημερωτικών φυλλαδίων (σχολές, βάσεις, κτλ)</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Εκδηλώσεις εκπαιδευτικού και ψυχολογικού χαρακτήρα</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Καθοδήγηση για τη συμπλήρωση των αιτήσεων σύμφωνα με τις  προκηρύξεις των ένστολων σχολών</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Καθοδήγηση για την τελική συμπλήρωση του μηχανογραφικού δελτίου</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Παροχή συγγραμμάτων</p>
                  <p>&nbsp;&nbsp;&nbsp;&#x27A4; Συνεργασία με ψυχολόγο και λογοθεραπευτή</p>
              </p>
            </div>
          </div>
          <div className="Paroxes__side-Containers">
            <div className="Paroxes__side-Container1">
              <h1>Χρήσιμες Σελίδες</h1>
              <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={['a']}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Πληροφορίες για Γ΄Λυκείου
                    </AccordionItemButton>
                  </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Exercitation in fugiat est ut ad ea cupidatat ut in
                        cupidatat occaecat ut occaecat consequat est minim minim
                        esse tempor laborum consequat esse adipisicing eu
                        reprehenderit enim.
                      </p>
                      <button class="custom-btn btn-5">Λεπτομέρειες</button>
                      </AccordionItemPanel>
                </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Μάθετε περισσότερα για την λειτουργεία του φροντιστηρίου μας
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          In ad velit in ex nostrud dolore cupidatat consectetur
                          ea in ut nostrud velit in irure cillum tempor laboris
                          sed adipisicing eu esse duis nulla non.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                      </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Σπουδές και επαγγέλματα
                      </AccordionItemButton>
                    </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          In ad velit in ex nostrud dolore cupidatat consectetur
                          ea in ut nostrud velit in irure cillum tempor laboris
                          sed adipisicing eu esse duis nulla non.
                        </p>
                        <button class="custom-btn btn-5">Λεπτομέρειες</button>
                      </AccordionItemPanel>
                  </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Paroxes